.search-advanced{

	.panel-heading{
		cursor:pointer;
	}

	.nav-tabs{
		border-bottom:0;
		margin-bottom:10px;
		margin-left:-1px;
		li{
			background:$color-white-smoker;
			border:0px;
			margin:1px;
			font-weight:bold;

			a:link,a:visited{
				border:0px;
			}
		}

		li.active{
			background-color:$color-white;
			border:0px;
		}
	}//nav-tabs

	.question-item{
		margin-bottom:10px;
		.add-filter{
			float:left;
			margin:-2px 7px 0 0;
		}
	}//question-item

	.second-title{
		margin:20px 0;
		margin-left:-$panel-body-padding;
		margin-right:-$panel-body-padding;
		padding:$panel-heading-padding;
		border-top:1px solid darken($color-white-smoke, 10%);
		border-bottom:1px solid darken($color-white-smoke, 10%);
		background-color:$color-white-smoke;
	}
}//search-advanced
#filter{
	.panel-heading{
		label{
			margin-top:5px;
		}
		.delete-filter{
			margin-top:4px;	
		}
	}
} // form#filter
.modal{
	.btn-loading{
		position: fixed;
		top:50%;
		left:50%;
		margin-left:-67px;
		margin-top:-22px;
	}

	.modal-header{
		background: $panel-default-heading-bg;
		letter-spacing: -1px;
  		color:$color-black;
  		padding:5px 10px;

  		h4{
  			font-size: 20px;
  		}

  		.close{
  			margin-top:5px;
  		}
	}//modal-header
	
	label.col-xs-2{
		font-weight:normal;
	}

	.modal-subtitle{
		border-top:1px solid $panel-default-border;
		border-bottom:1px solid $panel-default-border;
		background: $panel-default-heading-bg;
		padding:$panel-heading-padding;
		font-size:16px;
		margin:0;
	} 

	.modal-body{
		padding:10px 10px 0;

		.panel-group{
			margin-bottom:10px;
		}
	}
	.modal-footer{
		padding:5px 10px;
	}
	.nav-tabs{
		margin-left:-1px;
		border:0px;
		margin-bottom:10px;
		li{
			background:$color-white-smoker;
			border:0px;
			margin:1px;
			font-weight:bold;
			a,
			a:link,
			a:visited,
			a:hover {
				border:0px;
				padding:10px;
				cursor:pointer;
			}
		}

		li.active{
			background-color:$color-white;
			border:0px;
		}
	}//modal-header

	.question-item{
		width:100%;
		float:left;

		.add-filter{
			display:none;
		}
		
		.question-label{
			font-weight:bold;
			margin-bottom:10px;
			display:block;
		}

		& + .question-item{
			padding-top:10px;
			border-top:1px solid $panel-default-border;
		}
	} // question-item

	.panel{
		.panel-heading:hover{
			cursor:pointer;
			color:$color-black;
		}
		.panel-title[aria-expanded="true"]{
			color:$color-black;
		}
		.panel-title{
			font-size:14px;
			position:relative;
			.save-section{
				position:absolute;
				width:20px;
				right:0;
				top:1px;
			}

			.question-number{
				width: 120px;
  				margin-right: 20px;
  				padding: 2px 7px;
			}
		}

		.panel-group{
			margin-bottom:0;
		}
	} // panel
} // modal
/* ========================================================================
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 *
 * ========================================================================
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================================
 */

.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: $border-radius-base;
  border: 1px solid;
  border-color: $btn-default-border;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  z-index: 0;
  vertical-align: middle;
  @include user-select(none);
  @include transition("border-color ease-in-out .15s, box-shadow ease-in-out .15s");

  &-container {
    display: inline-block;
    top: 0;
    border-radius: $border-radius-base;
    @include translate3d(0, 0, 0);
  }

  &-handle-on,
  &-handle-off,
  &-label {
    @include box-sizing(border-box);
    cursor: pointer;
    display: inline-block !important;
    height: 100%;
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $line-height-computed;
  }

  &-handle-on,
  &-handle-off {
    text-align: center;
    z-index: 1;

    &.bootstrap-switch-primary {
      color: #fff;
      background: $btn-primary-bg;
    }

    &.bootstrap-switch-info {
      color: #fff;
      background: $btn-info-bg;
    }

    &.bootstrap-switch-success {
      color: #fff;
      background: $btn-success-bg;
    }

    &.bootstrap-switch-warning {
      background: $btn-warning-bg;
      color: #fff;
    }

    &.bootstrap-switch-danger {
      color: #fff;
      background: $btn-danger-bg;
    }

    &.bootstrap-switch-default {
      color: #000;
      background: $gray-lighter;
    }
  }

  &-label {
    text-align: center;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 100;
    color: $btn-default-color;
    background: $btn-default-bg;
  }

  &-handle-on {
    @include border-left-radius($border-radius-base - 1);
  }

  &-handle-off {
    @include border-right-radius($border-radius-base - 1);
  }

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: -1;
    @include opacity(0);

    &.form-control {
      height: auto;
    }
  }

  &-mini {
    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off,
    .bootstrap-switch-label {
      padding: $padding-xs-vertical $padding-xs-horizontal;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }

  &-small {
    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off,
    .bootstrap-switch-label {
      padding: $padding-small-vertical $padding-small-horizontal;
      font-size: $font-size-small;
      line-height: $line-height-small;
    }
  }

  &-large {
    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off,
    .bootstrap-switch-label {
      padding: $padding-base-vertical $padding-large-horizontal;
      font-size: $font-size-large;
      line-height: $line-height-large;
    }
  }

  &-disabled,
  &-readonly,
  &-indeterminate {
    cursor: default !important;

    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off,
    .bootstrap-switch-label {
      cursor: default !important;
      @include opacity(0.5);
    }
  }

  &-animate {
    .bootstrap-switch-container {
      @include transition(margin-left .5s);
    }
  }

  &-inverse {
    .bootstrap-switch-handle-on {
      @include border-left-radius(0);
      @include border-right-radius($border-radius-base - 1);
    }

    .bootstrap-switch-handle-off {
      @include border-right-radius(0);
      @include border-left-radius($border-radius-base - 1);
    }
  }

  &.bootstrap-switch-focused {
    color: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), 0.6);
    border-color: $input-border-focus;
    outline: 0;

    @include box-shadow("inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0,0,0,.075)");
  }

  &-on,
  &-inverse.bootstrap-switch-off {
    .bootstrap-switch-label {
      @include border-right-radius($border-radius-base - 1);
    }
  }

  &-off,
  &-inverse.bootstrap-switch-on {
    .bootstrap-switch-label {
      @include border-left-radius($border-radius-base - 1);
    }
  }
}


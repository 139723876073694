html {
	position: relative;
	min-height: 100%;
}

body {
	margin-bottom: 60px;

	.container{
		max-width: none !important;
		min-width:1170px;
	}

	& > .section-container{
		float:left;
		width:100%;
		display:block;
	}

	& > .section-container > .container{
		padding-bottom:5px;
		padding-top:5px;
		margin-bottom: 90px;
		background-color:$turkus;
	}
}
		
.main-content{
	padding:1px 10px 10px;
	background-color:$color-white;

	.panel{
		margin-bottom:0;

		.panel-title{
			.btn-xs{
				margin-top:-3px;
			}
		}
		.panel-group{
			margin-bottom:0;
		}
	}
}//main-content

.form-group{
	&.buttons{
		margin-bottom: 0;
	}
}//form-group
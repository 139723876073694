
h1,h2,h3,h4,h5,h6{
	color:$color-black;
}

h1{
	letter-spacing:-1px;
	font-size:20px !important;
	color:$color-black !important;
	margin-top:5px !important;
}



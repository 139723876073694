.search-simple{
	.panel-heading{
		cursor:pointer;
	}

	.question-title{
		margin-bottom:20px;
		margin-left:-$panel-body-padding;
		margin-right:-$panel-body-padding;
		padding:$panel-heading-padding;
		border-top:1px solid darken($color-white-smoke, 10%);
		border-bottom:1px solid darken($color-white-smoke, 10%);
		background-color:$color-white-smoke;

		h5{
			margin:0;
			font-size:16px;
		}
	}

	.col-switch{
		height:28px;		
	}

	.row{
		.col-switch:last-child{
			& .bootstrap-switch{
				float:right;
			}
		}

		&.text-left{
			.control-label{
				text-align: left;
			}
		}
	}

	#brand_id_chosen{
		.chosen-single{
			height: 32px;

			b,span,abbr{
				margin-top: 4px;
			}
			abbr{
				cursor: pointer;
			}
		}
	}
}
//--- also used in searchadvanced
.custom{
		&:last-child{
			margin-bottom:5px;
		}

		.col-xs-8,
		.col-xs-9,
		.col-xs-10,
		.col-xs-11,
		.col-xs-12{
			.labels{
				background-color:$color-white-smoker;
				float:left;
				width:100%;

				label{
					float:left;
					text-align: center;
					background-color:$color-white-smoker;
					margin:0;
					padding:5px 0;
					font-size:11px;
				}
			}
			.radio > div,
			.checkbox > div{
				text-align: center;
				float:left;
				background-color:$color-white-smoke;
				padding:4px 0;
			}
		}

		label.col-xs-12{
			text-align:left;
		}

		&.labels{
			& > label{
				font-weight:normal;
			}
		}

		.checkbox{
			padding-top:0;
		}

		.checkbox,
		.radio{
			margin-top:0;
		}
	}

.action.form-group{
	margin-bottom:0;
}

//--- display radio checkbox like a table

.block-table{
	display:table;

	.block-row{
		display: table-row;

		.block-cell{
			display:table-cell;
			float:none;
			vertical-align: top;
			background-color:$color-white-smoke;
			padding-bottom:5px;
			border-top:5px solid $color-white;

			& + .block-cell {
				border-left:5px solid $color-white;
			}

			& > div{
				padding-left:10px;
				padding-right:10px;
			}
		}
	}
}

//--- end - also used in searchadvanced
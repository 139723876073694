form[id^=postcase_]{
    position: relative;

    .save-case-form{
        position: absolute;
        top: 0;
        
        i{
            cursor: pointer;
            margin-right: 5px;
        }
    }
}

#form_field_content_190{
    h3{
        background: #e8e8e8;
        border: 1px solid rgba(0,0,0,.1);
        padding: 4px;
        margin-top: 0;
    }
    
    .line{
        border-top: 1px solid rgba(0,0,0,.1);
    }

    .col-xs-3.row-g{
        padding-top: 6px;
        padding-bottom: 6px;
    }

    
    .columns-header.row{
        display: flex;
        display: -webkit-flex;
        .col-xs-3{
            align-self: flex-end;
        }
    }
    
    .inline-input{
        width: 50%;
        margin-right: 10px;
        display: inline-block;
    }
}
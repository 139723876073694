footer{
	min-width:1170px;
	position: absolute;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	padding-top:10px;
	height: 90px;
	background-color: $color-black;
	color:$color-white;

	.tb{
		color:$color-white;
		img{width:100px;}
	}
	.copy{
		font-size:0.8em;
		color:$color-grey;
		margin-top:5px;
	}
}
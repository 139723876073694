.dataTables_wrapper{

	font-size:13px;

	.DTTT_container{
		margin-right:10px; 
		margin-bottom:0;
		.DTTT_button,
		.DTTT_button:link,
		.DTTT_button:link:visited,
		.DTTT_button:hover{
			border:1px solid $color-very-light-grey;
			-webkit-box-shadow: 0 0 0;
			-moz-box-shadow: 0 0 0;
			-ms-box-shadow: 0 0 0;
			-o-box-shadow: 0 0 0;
			box-shadow: 0 0 0;
		}
	}

	label{
		margin-bottom:0;
	}
}//dataTables_wrapper

.dataTable{
	ul{
		margin-bottom:0;
	}
}//dataTable
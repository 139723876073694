header{

	float:left;
	width:100%;
	display:block;
	min-width:1170px;

	.navbar{
		margin-bottom: 0;
		min-width:1170px;
	}

	.logo{
		img{
			margin:10px 20px 10px 0;
			height:40px;
		}
	}

	.slogan{
		margin-top:10px;

		span{
		  	font-family: 'Georgia';
			font-size: 15px;
			color: $color-dim-grey;
			margin:10px 0 0 10px;
			height: 18px;
			transition: height 500ms;
		}
	}

	ul.navbar-nav{
		margin-top:15px;

		/*@media(max-width:$screen-sm){
			margin:0;
		}*/

		& > li{
			padding-bottom:8px;

			/*@media(max-width:$screen-sm){
				padding-bottom:0;
			}*/

			& > a:link,a:visited{
				font-weight:bold;
				color:$color-black;
				font-size:17px;
				letter-spacing: -1px;
				padding: 3px 9px;
			}

			& > a:hover{
				background-color: $color-white !important;
			}
		}

		& > li + li{
			a{
				border-left:1px solid $color-very-light-grey;
				/*@media(max-width:$screen-sm){
					border:0;
				}*/
			}
		}

		& > li.active{
			background-color:transparent;
			border-bottom:3px solid $turkus;

			/*@media(max-width:$screen-sm){
				border:0;
			}*/

			a{
				background-color:transparent !important;
			}
		}

		.dropdown-toggle{
			&[aria-expanded='true']{
				background-color:$color-white !important;
			}
		}

		.dropdown-menu{
			border:0;
			top:26px;

			li > a:link,
			li > a:visited{
				font-weight: bold;
				color:$color-charcoal;
				font-size:12px;
			}

			li > a:hover,
			li > a:active{
				color:$color-black;
				background-color:$color-white;
			}

			.divider{
				margin:6px 0;
			}
		}
	}//navbar-nav
}//header